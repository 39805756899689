import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Xulaservice } from './service/Xulaservice';

export class AppBreadcrumb extends Component {

    constructor() {
        super();

        this.state = {
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            message: '',
            connection: 'O',
            color: '#FC1805',
        }
        this.xulaservice = new Xulaservice();
    }

    static propTypes = {
        match: PropTypes.object
    }


    componentDidMount() {

    }


    componentWillUnmount() {

    }




    render() {
        const { location } = this.props;
        const paths = location.pathname.split('/');
        return (
            <div className="layout-breadcrumb">
                <ul>
                    <li><button className="p-link"><i className="fa fa-home"></i></button></li>
                    {
                        location.pathname === '/' ? <li>/</li> : paths.map((path, index) => <li key={index}>{path === '' ? '/' : path}</li>)
                    }
                </ul>
                {/* <marquee style={{ color: 'red', fontWeight: 'bold', fontSize: 18, width: '60%', marginTop: 10 }}>{this.state.message}</marquee> */}
                <div className="layout-breadcrumb-options">


                    <button className="p-link" title="Changer mot de passe" onClick={(e) => {
                        window.location = "#/changer_mot_de_pass";
                    }} >
                        <i className="fa fa-user"></i>
                    </button>
                    <button className="p-link" title="Logout" onClick={(e) => {
                        localStorage.removeItem('message');
                        localStorage.removeItem('userconnected');
                        localStorage.removeItem('offline', 'false');
                        localStorage.removeItem('srv_url', 'https://new.xulanet.com/ws_xula/xula');
                        window.location = "#/login";
                    }} >
                        <i className="fa fa-sign-out"></i>
                    </button>
                </div>
                <br />


            </div>
        );
    }
}